<template>
  <card title="成果展示" :withbg="true">
    <el-row class="r-achieve-list" :gutter="20">
      <el-col :span="6" v-for="item of showList" :key="item.code">
        <div class="achieve-col">
          <p><span class="num">{{ item.num }}</span> <span class="unit">{{ item.unit }}</span></p>
          <p class="dec">
            <img :src="item.icon" />
            {{ item.name }}
          </p>
        </div>
      </el-col>
    </el-row>
  </card>
</template>

<script>
import Card from "@/components/Card/index.vue";
import { mapGetters } from "vuex";
import {
  // getFlowNumberInfo,
  // staticCjmjByCqlx,
  // getJtCountNum,
  // getContractFlowAreaInfo,
  // staticJtCjmjByCqlx,
  // getJTJSAreaInfo,

} from "@/api/resourceAssetService.js";
import {SelectXMXXFarmerCount,  SelectXMXXCompanyCount,SelectXMXXManageCount} from "@/api/displayResults.js";



const showList = [
  {
    code: 1,
    num: 0,
    unit: "宗",
    icon: require("@/assets/icon-show-8.png"),
    name: "农户累计流转",
  },
  {
    code: 2,
    num: 0,
    unit: "亩",
    icon: require("@/assets/icon-show-2.png"),
    name: "农户累计流转",
  },
  {
    code: 3,
    num: 0,
    unit: "万元",
    icon: require("@/assets/icon-show-3.png"),
    name: "农户累计流转",
  },
  {
    code: 4,
    num: 0,
    unit: "亩",
    icon: require("@/assets/icon-show-10.png"),
    name: "承包经营权流转",
  },
  {
    code: 5,
    num: 0,
    unit: "宗",
    icon: require("@/assets/icon-show-9.png"),
    name: "集体累计交易流转",
  },
  {
    code: 6,
    num: 0,
    unit: "亩",
    icon: require("@/assets/icon-show-5.png"),
    name: "集体累计交易流转",
  },
  {
    code: 7,
    num: 0,
    unit: "万元",
    icon: require("@/assets/icon-show-6.png"),
    name: "集体累计交易流转",
  },
  {
    code: 8,
    num: 0,
    unit: "亩",
    icon: require("@/assets/icon-show-8.png"),
    name: "集体建设用地出让",
  },
];

export default {
  components: {
    Card,
  },
  computed: mapGetters(["topSelectAreacode"]),
  data() {
    return {
      showList,
    };
  },
  watch:{
    topSelectAreacode(){
      this.init();
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // this.getLZS();
      // this.getArea();
      // this.getCollectiveNum();
      // this.getCollectiveArea();
      // this.getGDLZMJ();
      // this.getJTCRMJ();
      this.getOne()
      this.getTwo()
      this.getThree()
    },
    async getOne(){
      let res= await SelectXMXXFarmerCount({
        areaCode:this.topSelectAreacode
      })
      // console.log(res,'查看one数据')
      if(res.code==0){
          this.showList[0].num=res.data.countNumber? res.data.countNumber:0
          this.showList[1].num=res.data.totalArea? res.data.totalArea:0
          this.showList[2].num=res.data.totalAmount? (res.data.totalAmount/10000).toFixed(2):0
      }
    },
    async getTwo(){
      let res= await SelectXMXXCompanyCount({
        areaCode:this.topSelectAreacode
      })
      // console.log(res,'查看Two数据')
      if(res.code==0){
          this.showList[4].num=res.data.countNumber? res.data.countNumber:0
          this.showList[5].num=res.data.totalArea? res.data.totalArea:0
          // this.showList[1].num=res.data.totalArea? res.data.totalArea:0
          this.showList[6].num=res.data.totalAmount? (res.data.totalAmount/10000).toFixed(2):0
      }
    },
    async getThree(){
     let res =await SelectXMXXManageCount({
       areaCode:this.topSelectAreacode
   })

   if(res.code==0){
    this.showList[3].num=res.data.contractOperationArea? res.data.contractOperationArea:0
    this.showList[7].num=res.data.collectiveConstructionArea? res.data.collectiveConstructionArea:0
    }
  }
    // //农户累计流转数
    // async getLZS() {
    //   let res = await getFlowNumberInfo();
    //   if (res.status) {
    //     this.showList.forEach((item) => {
    //       if (item.code == 1) {
    //         item.num = res.data ? res.data : 0;
    //       }
    //     });
    //   }
    // },

    // async getArea() {
    //   let res = await staticCjmjByCqlx();
    //   if (res.status) {
    //     this.showList[1].num = res.totalMj ? res.totalMj.toFixed(2) : 0;
    //     this.showList[2].num = res.totalJe ? (res.totalJe / 10000).toFixed(2) : 0;
    //   }
    // },


    // //耕地承包经营权流转面积
    // async getGDLZMJ() {
    //   let res = await getContractFlowAreaInfo();
    //   if (res.status) {
    //     this.showList.forEach((item) => {
    //       if (item.code == 4) {
    //         item.num = res.data ? res.data.toFixed(2) : 0;
    //       }
    //     });
    //   }
    // },

    // // 集体累计交易流转数量
    // async getCollectiveNum() {
    //   let res = await getJtCountNum();

    //   if (res.status) {
    //     this.showList[4].num = res.data.toFixed(2) ? res.data : 0;
    //   }
    // },

    // // 集体累计交易流转
    // async getCollectiveArea() {
    //   let res = await staticJtCjmjByCqlx();
    //   if (res.status) {
    //     this.showList[5].num = res.totalMj ? res.totalMj.toFixed(2) : 0;
    //     this.showList[6].num = res.totalJe ? (res.totalJe / 10000).toFixed(2) : 0;
    //   }
    // },
    // //集体建设用地出让面积
    // async getJTCRMJ() {
    //   let res = await getJTJSAreaInfo();
    //   if (res.status) {
    //     this.showList[7].num = res.data ? res.data.toFixed(2) : 0;
    //   }
    // },
  },
};
</script>

<style lang="less" scope>
.r-achieve-list {
  padding-top: 20px;
  height: 320px;
  .achieve-col {
    background: #fff;
    border: 1px solid #dcdcdc;
    padding: 10px 0px 20px 30px;
    margin: 15px 0;
    p {
      padding: 0;
      margin: 0;
    }

    .num {
      display: inline-block;
      max-width: 280px;
      font-size: 48px;
      color: #333;
    }

    .dec {
      font-size: 18px;
      color: #666;
      display: flex;
      align-items: center;

      img {
        margin-right: 6px;
      }
    }
  }
}
</style>
