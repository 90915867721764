//资源资产服务相关接口
import service from "../request";

const baseUrl = window.globalUrl.HOME_API;

//1、成果展示

// 农户累计流转
//累计交易流转数量
export const getFlowNumberInfo = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/trade-website/cqjyService/gettaccumulatenum`,
    params: params,
  });
};

// 后台管理--根据产权类型统计农户成交面积
export const staticCjmjByCqlx = (params) => {
  return service({
    method: "post",
    url: `${baseUrl}/trade-website/cqjyService/staticCjmjAndJeByCqlx`,
    data: params,
  });
};

// 后台管理--根据产权类型统计农户成交金额
export const staticCjjeByCqlx = (params) => {
  return service({
    method: "post",
    url: `${baseUrl}/trade-website/cqjyService/staticCjjeByCqlx`,
    data: params,
  });
};

//累计交易流转面积
export const getFlowAreaInfo = (params) => {
  return service({
    method: "post",
    url: `${baseUrl}/trade-website/cqjyService/staticCjmjAndJe`,
    data: params,
  });
};

//累计交易流转金额
export const getFlowAmountInfo = (params) => {
  return service({
    method: "post",
    url: `${baseUrl}/trade-website/cqjyService/staticCjje`,
    data: params,
  });
};

//承包经营权流转面积
export const getContractFlowAreaInfo = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/trade-website/cqjyService/getcontractarea`,
    params: params,
  });
};

export const getJtCountNum = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/trade-website/cqjyService/getJtCountNum`,
    params: params,
  });
};

// 根据产权类型统计集体成交面积
export const staticJtCjmjByCqlx = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/trade-website/cqjyService/staticJtCjmjAndJeByCqlx`,
    params: params,
  });
};

// 根据产权类型统计集体成交金额
export const staticJtCjjeByCqlx = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/trade-website/cqjyService/staticJtCjjeByCqlx`,
    params: params,
  });
};

//集体建设用地出让面积
export const getJTJSAreaInfo = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/trade-website/cqjyService/getjtarea`,
    params: params,
  });
};

//2、流转交易动态
export const getFlowDynamicInfo = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/trade-website/cqjyService/getCjDynamics`,
    params: params,
  });
};

//3、交易流转统计
//交易流转区域面积排名
export const getFlowAreaSort = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/trade-website/cqjyService/countarea`,
    params: params,
  });
};

export const staticcountarea = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/trade-website/cqjyService/staticcountarea`,
    data: params,
  });
};
